import axios from "axios";

const baseUrl = "http://localhost:3001"

export default {
    async get(endpoint) {
        const res = await axios.get(`${baseUrl}${endpoint}`);
        return res.data;
    },
    async post(endpoint, data) {
        const res = await axios.post(`${baseUrl}${endpoint}`, data);
        return res.data;
    }
};