import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";

export default function CreateShop(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState('');

    const postCreateShop = (e) => {
        setLoading(true);
        const { botToken } = e.target;
        api.post(`/createShop?${props.initData}`, { botToken:botToken.value })
        .then(res => {
            setLoading(false);
            const { ok, data, err } = res;
            if (ok) {
                navigate(`/manageShop/`);
            } else {
                err === 404 ? setErr("Invalid bot token") : setErr(err);
            }
        })
    };

    return (
        <div>
            <h1>Create New Shop</h1>
            <p>Start by entering your bot token!</p>
            <p>Tip: get your bot token from <a href="https://t.me/BotFather">@BotFather</a></p>
            <form onSubmit={e => {e.preventDefault();postCreateShop(e)}}>
                <div className="input-group mb-3">
                    <span className="input-group-text" id="botTokenLabel">Bot Token</span>
                    <input type="text" className={`form-control ${err ? 'is-invalid' : ''}`} name="botToken" placeholder="123456:ABC-DEF1234ghIkl-zyx57W2v1u123ew11" aria-label="Bot Token" aria-describedby="botTokenLabel" required />
                    <div className="invalid-feedback">
                        {err}
                    </div>
                </div>
                <button className={`btn btn-primary d-flex align-items-center ${loading ? 'disabled' : ''}`}>{loading ? <div className="spinner-border text-light me-2" /> : ''}Continue</button>
            </form>
        </div>
    )
};