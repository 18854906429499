import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateShop from './components/CreateShop';

function App() {
  const { initData } = window.Telegram.WebApp;
  return (
    <div className="App">
      <nav className="navbar navbar-light bg-light d-flex p-3 justify-content-end">
        <div className="dropdown">
          <button className="btn btn-secondary dropdown-toggle" type="button" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false">
            @
          </button>
          <ul className="dropdown-menu" aria-labelledby="userDropdown">
            <li><a className="dropdown-item" href="#"><i className="bi-person-circle" /> My Account</a></li>
            <li><a className="dropdown-item" href="#"><i className="bi-life-preserver" /> Help</a></li>
          </ul>
        </div>
      </nav>
      <div className="container mt-3">
        <p>{initData}</p>
        <CreateShop initData={initData} />
      </div>
    </div>
  );
}

export default App;
